<template>
  <b-card v-if="datas.length > 0">
        <div class="chart-wrapper">
          
        <h5 class="mb-3">{{ title }} 



            <div class="pull-right mt-1" v-if="legends.length > 0" style="font-size:14px;">
                <small :key="index" v-for="(l, index) in legends" class="pull-left ml-2">
                    <span :style="{'background-color':color[index], 'width':20, 'height':20}" >&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ l }}
                </small> 
            </div>
            <div class="pull-right mt-1 text-primary" v-else-if="sumData > 0 && showType == 'LAST_6_MONTH'" style="font-size:16px;">
                {{ sumData|currecry }}₮
            </div>
        </h5>
        
        <bar-chart
        :id="this.type"
        v-if="!isLoading"
        :chartdata="chartdata"
        :options="options"
        :style="{ height: '250px' }"
        />
        </div>
    </b-card>
</template>

<script>
import LineChart from './../base/Chart/Line.vue'
import BarChart from './../base/Chart/Bar.vue'
import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { setTimeout } from 'timers';
import moment from 'moment'

export default {
    name: 'ChartContainer',
    components: { 
      LineChart,
      BarChart,
      hexToRgba
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        startDate: {
            type: String,
            default: moment().subtract(6, 'days').format('YYYY-MM-DD')
        },
        endDate: {
            type: String,
            default: moment().format('YYYY-MM-DD')
        },
        showType: {
            type: String,
            default: 'LAST_WEEK'            
        },
        color: {
            type: Array,
            default: () => ['#067351']
        },
        value_type: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => []
        },
        legends: {
            type: Array,
            default: () => []
        },
        dataWithLabel: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isLoading: false,
        chartdata: null,
        labels: [],
        datas: [],
        options: {},
        sumData: 0,
    }),
    created() {        
        this.$data.options = this.$config.CHART_OPTIONS;
        this.setChartData()
    },
    watch: {
        data: {
            handler (data) {
                this.setChartData();
            },
        deep: true
        },
    },
    methods:{
        generateData: function() {
            if(this.dataWithLabel ) {
                this.$data.datas = this.data[0]
                this.$data.labels = this.data[1]
            } else {
                var start = new Date(this.startDate);
                var end = new Date(this.endDate);

                this.$data.labels = []
                while(start <= end){
                    
                    if(this.showType == 'LAST_WEEK') {
                        this.$data.labels.push(moment(start).format('YYYY-MM-DD'));
                        var newDate = start.setDate(start.getDate() + 1);
                    } else {
                        this.$data.labels.push(moment(start).format('YYYY-MM'));
                        var newDate = start.setMonth(start.getMonth() + 1);
                    }
                    
                    start = new Date(newDate);  
                }
                this.$data.datas = this.data
            }
        },
        setChartData: function() {
            this.generateData();
            this.isLoading = true;
            var _datasets = []
            for(var i in this.$data.datas) {
                if(this.$data.datas[i] !== undefined) {
                    _datasets.push( {
                        pointBorderWidth: 0,
                        pointRadius: 3,
                        pointBackgroundColor: hexToRgba(this.color[i], 100),
                        backgroundColor: hexToRgba(this.color[i], 80),
                        hoverBackgroundColor: hexToRgba(this.color[i], 100),
                        borderWidth: 1,
                        borderColor: hexToRgba(this.color[i], 90),
                        data: this.$data.datas[i].slice(0, this.$data.labels.length)
                    });
                    if(this.$data.datas[i].length > this.$data.labels.length) {
                        this.$data.sumData = this.$data.datas[i][this.$data.labels.length]
                    }
                } else {
                    _datasets.push( {   
                        pointBorderWidth: 0,
                        pointRadius: 3,
                        pointBackgroundColor: hexToRgba(this.color[i], 100),
                        backgroundColor: hexToRgba(this.color[i], 80),
                        hoverBackgroundColor: hexToRgba(this.color[i], 100),
                        borderWidth: 1,
                        borderColor: hexToRgba(this.color[i], 90),
                        data: 0
                    });
                }
                
            }
            this.chartdata = {
                labels: this.$data.labels,
                datasets: _datasets
            }
            setTimeout(function() {
                this.isLoading = false;
            }.bind(this), 10);
        }
    } 
}
</script>