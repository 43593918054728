<template>
    <b-card :class="[bg_color, text_color]">
        <div class="h4 m-0">{{ (this.data.length > ind) ? this.data[ind]: 0 }}{{ (value_types.hasOwnProperty(this.value_type)?value_types[this.value_type]:'') }} </div>
        <small>{{ label }}</small>
    </b-card>
</template>

<script>
import moment from 'moment'

export default {
    name: 'Card',
    props: {
        ind: {
            type: Number,
            default: 0
        },
        label: {
            type: String, 
            default: ''
        },
        bg_color: {
            type: String, 
            default: ''
        },
        text_color: {
            type: String,            
            default: ''
        },
        url: {
            type: String,
            default: 'ReportWebService/get_dashboard_card'
        },
        type: {
            type: String,
            default: ''
        },
        value_type: {
            type: String,
            default: ''
        },
        startDate: {
            type: String,
            default: '2000-01-01'
        },
        endDate: {
            type: String,
            default: moment().format('YYYY-MM-DD')
        },
        data: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        value_types: {
            amount: '₮',
            percent: '%',
            month: ' сар'
        }
    }),
}
</script>